import React from 'react';
import Layouts from 'layouts';
import PropTypes from 'prop-types';
import { PromotionDetailNewSlotDaly30Percent } from 'screens';
import { useIntl } from 'gatsby-plugin-intl';
import OgImage from 'images/promotions/new-slot-daly-30%.jpg';

// new-slot-daly-30-percent

const PromotionsPage = (props) => {
  const intl = useIntl();
  const { location } = props;

  return (
    <Layouts
      title={intl.formatMessage({ id: 'page.home.title' })}
      description={intl.formatMessage({ id: 'page.home.description' })}
      image={OgImage}
      pathname={location.pathname}
    >
      <PromotionDetailNewSlotDaly30Percent />
    </Layouts>
  );
};

PromotionsPage.propTypes = {
  location: PropTypes.string.isRequired,
};

export default PromotionsPage;
